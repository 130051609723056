/**************************************
    Default Styles
***************************************/

@import 'bootstrap/dist/css/bootstrap.min.css';
// @import url('../css/bootstrap/bootstrap-utilities.css');
// @import url('../css/bootstrap/bootstrap-grid.css');
@import url('../css/slick.css');
@import url('../css/slicktheme.css');
@import url('../css/animation.css');


@import 'default/variables';
@import 'default/mixins';
@import 'default/reset';
@import 'default/typography';
@import 'default/extend';
@import 'default/animations';
@import 'default/shortcode';
@import 'default/spacing';
@import 'default/common';
@import 'default/forms';


/**************************************
    Theme Styles
***************************************/

@import 'color/themebg';

/**************************************
    Header Styles
***************************************/
@import 'header/header';
@import 'header/nav';
@import 'header/mobilemenu';
@import 'header/headertop';


/**************************************
    Elements Styles
***************************************/
@import 'elements/sctiontitle';
@import 'elements/button';
@import 'elements/breadcrumb';
@import 'elements/accordion';
@import 'elements/service';
@import 'elements/card';
@import 'elements/progressbar';
@import 'elements/counter';
@import 'elements/portfolio';
@import 'elements/social';
@import 'elements/team';
@import 'elements/timeline';
@import 'elements/testimonial';
@import 'elements/slickslide';
@import 'elements/tab';
@import 'elements/pricing';
@import 'elements/split';
@import 'elements/callto-action';
@import 'elements/video';
@import 'elements/contact';
@import 'elements/brand';
@import 'elements/newsletter';
@import 'elements/advance-tab';
@import 'elements/advancepricing';
@import 'elements/about';
@import 'elements/swicher';
@import 'elements/404';



/**************************************
    Blog Styles
***************************************/

@import 'blog/blog';
@import 'blog/sidebar';
@import 'blog/blog-details';



/**************************************
    Footer Styles
***************************************/
@import 'template/banner';
@import 'template/portfoliodetails';
@import 'template/splash';



/**************************************
    Footer Styles
***************************************/

@import 'footer/footer';
@import 'footer/copyright';
@import 'footer/darkmode';

.service.gallery-style.service__style--1 {
    min-height: 380px;
}
.mob-img{
    max-width: 220px;
    margin: 0 auto;
}
p.web-paragraph {
    max-width: 630px;
}
.footer-contact ul li a{list-style: none !important; font-size: 14px;}
.client-section-gap{padding: 80px 0 0 0 !important;}
.thumbnail-tech img {border-radius: 20px;}
.web-thumbnail img {border-radius: 20px; border: 1px solid #0101;}

.shopify-experts img, .shopify-plus img{
    max-width: 200px;
}
.client-testimonial {
    margin: 20px;
    border: 3px solid #2B60D4;
    padding: 28px 30px;
    border-radius: 10px;
    min-height: 360px;
    max-height: 360px;
}
.testimonial-wrap {
    margin-bottom: 20px;
}
.testimonial-section{
 background-image: url(../images/testimonial.jpg);
}
// .slider-area {
//     background-image: url(../images/wave-bg.svg);
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: top center;
// }


// 25 Septemer 2023 Added css

.cta-btn{   
    background-color: #198754 !important;
    margin:10px;
}
//11 December 2023 CSS
.nav-mob a {
    display: inline-block !important;
    padding-left: 6px !important;
    padding-right: 0 !important;
    color: #3671EE !important;
}
li.nav-mob {
    padding-left: 15px;
    color: #3671EE !important;
}
li.nav-mob:hover, .mainmenu > li.nav-mob a:hover{
    color: #65676b !important;
}
// .mainmenu > li.nav-mob a:hover::after{
//     content: unset;
// }
@media only screen and (max-width: 1199px){
    .client-testimonial {
        overflow-y: scroll;
    } 
}
  h2.title.awesome-site {min-height: 110px;}
  .typed-cursor{display: none !important;}
  @media only screen and (max-width: 991px){
    .testimonial-img{
        margin-left: 0;
         }
         li.nav-mob {
            padding-left: 0;
            color: #3671EE !important;
        }
  }
  @media only screen and (max-width: 767px){
    
    .clients-text{
        text-align: center;
    }
    .clients-img{
        display: flex;
        justify-content: center;
    }
  }